import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
// import { authHeader } from "./auth-header";
/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    // Vue.axios.defaults.baseURL = "http://127.0.0.1:8000/api/";
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {

    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch((error) => {
      // console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    if (slug == "") {
      return Vue.axios.get(`${resource}`).catch((error) => {
        // console.log(error);
        throw new Error(`[KT] ApiService ${error}`);
      });
    } else {
      return Vue.axios.get(`${resource}/${slug}`).catch((error) => {
        // console.log(error);
        throw new Error(`[KT] ApiService ${error}`);
      });
    }
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },
  upload(resource, params) {
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    return Vue.axios.post(`${resource}`, params, config);
  },
  // post(resource, params) {
  //   return Vue.axios.post("http://127.0.0.1:8000/api/login", params);
  // },
  // login(user) {
  //   return axios
  //     .post(API_URL + 'login', {
  //       email: user.email,
  //       password: user.password
  //     })
  //     .then(response => {
  //       
  //       if (response.data.token) {
  //         localStorage.setItem('user', JSON.stringify(response.data));
  //       }

  //       return response.data;
  //     });
  // },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  downloadFile(resource, params){
    window.open(`${Vue.axios.defaults.baseURL}${resource}/${params}`,'_blank')
    return
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource, slug) {
    return Vue.axios.delete(`${resource}/${slug}`).catch((error) => {
      // console.log(error);
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },
};

export default ApiService;
